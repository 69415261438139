import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../img/logo.png";

import amex from "../img/cards/amex.svg";
import diners from "../img/cards/diners.svg";
import discover from "../img/cards/discover.svg";
import jcb from "../img/cards/jcb.svg";
import mastercard from "../img/cards/mastercard.svg";
import unionpay from "../img/cards/unionpay.svg";
import visa from "../img/cards/visa.svg";

import lock from "../img/lock.svg";

import valid from "card-validator";
import axios from "axios";

import { LoginFailure } from "./LoginFailure";
import { country_list, states } from "../json/country_state";

export const Login = () => {
  // component controlls
  const [failure, setfailure] = useState(false);

  const [to_show_states, setto_show_states] = useState([]);

  const [signup, setsignup] = useState(false);

  const [submit, setsubmit] = useState(false);

  const [card_type, setcard_type] = useState(false);

  // user data with err states and required states
  const [name, setname] = useState("");
  const [name_required, setname_required] = useState("");

  const [email, setemail] = useState("");
  const [email_err, setemail_err] = useState("");
  const [email_required, setemail_required] = useState(false);

  const [phone, setphone] = useState("");
  // const [phone_err, setphone_err] = useState("");
  const [phone_required, setphone_required] = useState(false);

  const [add1, setadd1] = useState("");
  const [add1_required, setadd1_required] = useState(false);

  const [add2, setadd2] = useState("");
  const [add2_required, setadd2_required] = useState(false);

  const [pincode, setpincode] = useState("");
  const [pincode_required, setpincode_required] = useState(false);

  const [country, setcountry] = useState("");

  const [selectedstates, setselectedstates] = useState("");

  const [cardno, setcardno] = useState("");
  const [cardno_err, setcardno_err] = useState("");
  const [cardno_required, setcardno_required] = useState(false);

  const [carddate, setcarddate] = useState("");
  const [carddate_err, setcarddate_err] = useState("");
  const [carddate_required, setcarddate_required] = useState(false);

  const [cardcvv, setcardcvv] = useState("");
  const [cardcvv_err, setcardcvv_err] = useState("");
  const [cardcvv_required, setcardcvv_required] = useState(false);

  const navigate = useNavigate();

  const OnChangePhone = (e) => {
    // console.log([9, 13].some((el) => el == e.target.value.length));
    if (
      Number(e.slice(-1)).toString() == "NaN" ||
      e.length > 17 ||
      (e.slice(-1) == " " && ![5, 9, 13].some((el) => el == e.length))
    ) {
      return;
    }
    console.log(e.length);
    setphone_required(true);
    if (e.length == 17) {
      setphone_required(false);
    }
    switch (e.length) {
      case 1:
        return setphone(`(+1) ${e[0]}`);
      case 5:
        return setphone("");
      case 9:
        if (e.slice(-1) == " ") {
          return setphone(e.slice(0, 8));
        }
        return setphone(e.slice(0, 8) + " " + e.slice(-1));
      case 13:
        if (e.slice(-1) == " ") {
          return setphone(e.slice(0, 12));
        }
        return setphone(e.slice(0, 12) + " " + e.slice(-1));

      default:
        return setphone(e);
    }
  };

  useEffect(() => {
    // sets initial country as 'US'
    setcountry("US");
  }, []);

  useEffect(() => {
    // error handling for states list
    if (to_show_states?.length) {
      setselectedstates(to_show_states[0].name);
      return;
    } else {
      setselectedstates([]);
    }
  }, [to_show_states]);

  useEffect(() => {
    // sets states of specified country
    setto_show_states(states[country]);
  }, [country]);

  useEffect(() => {
    // email checker and error setter
    if (
      email != "" &&
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setemail_err("Your email is incomplete.");
    } else {
      setemail_err("");
    }
  }, [email]);

  useEffect(() => {
    if (cardno.length > 0) {
      // console.log(cardno, cardno.split(" ").join("").length);
      if ([15, 16, 19].indexOf(cardno.split(" ").join("").length) == -1) {
        setcardno_err("Your card number is incomplete.");
      } else {
        setcardno_err("");
      }
    } else {
      setcardno_err("");
    }
  }, [cardno]);

  useEffect(() => {
    // carddate checker and error setter
    let date_arr = carddate.split("/").map((el) => el.trim());
    const current_date = new Date();
    var entered_date = new Date("20" + date_arr[1], date_arr[0] - 1, 31);
    if (current_date > entered_date && date_arr[1]?.length == 2) {
      setcarddate_err("Your card's expiration year is in the past.");
    } else if (carddate != "" && (date_arr[1] < 10 || date_arr.length != 2)) {
      setcarddate_err("Your card's expiration date is incomplete.");
    } else {
      setcarddate_err("");
    }
  }, [carddate]);

  useEffect(() => {
    // cardcvv checker and error setter
    if (cardcvv.length < 3 && cardcvv.length > 0) {
      setcardcvv_err("Your card's security code is incomplete.");
    } else {
      setcardcvv_err("");
    }
  }, [cardcvv]);
  useEffect(() => {
    // submit btn checker for all inputs ...
    if (
      (email_err + cardno_err + carddate_err + cardcvv_err).length == 0 &&
      !(
        email_required &&
        phone_required &&
        cardno_required &&
        carddate_required &&
        cardcvv_required &&
        name_required &&
        add1_required &&
        add2_required &&
        pincode_required
      ) &&
      email != "" &&
      phone != "" &&
      phone.length == 17 &&
      cardno != "" &&
      carddate != "" &&
      cardcvv != "" &&
      name != "" &&
      add1 != "" &&
      add2 != "" &&
      pincode != ""
    ) {
      setsubmit(true);
    } else {
      setsubmit(false);
    }
  }, [
    name,
    name_required,
    email_err,
    email_required,
    phone,
    phone_required,
    add1,
    add1_required,
    add2,
    add2_required,
    pincode,
    pincode_required,
    cardno_err,
    cardno_required,
    carddate_err,
    carddate_required,
    cardcvv_err,
    cardcvv_required,
  ]);
  // check with these logs to check a set of input if it works or not
  // console.log('email', [email, email_err, email_required]);
  // console.log('cardno', [cardno, cardno_err, cardno_required, card_type]);
  //  .log('carddate', [carddate, carddate_err, carddate_required]);
  // console.log('cardcvv', [cardcvv, cardcvv_err, cardcvv_required]);
  // console.log('name', [name, name_required]);
  // console.log('country', [country]);

  const set_value_cardno = (current) => {
    // checks for a number as a input
    if (
      Number(current.target.value.substr(-1)) == current.target.value.substr(-1)
    ) {
      // checks for the length of value to be less than 20
      if (current.target.value.split(" ").join("").length <= 19) {
        // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.target.value.length) > -1 &&
          cardno.length <= current.target.value.length
        ) {
          setcardno(cardno + " " + current.target.value.slice(-1));
        } else {
          setcardno(current.target.value);
        }
        let data = (current.target.value + "").split(" ").join("");
        let numberValidation = valid.number(data);
        // finds type of card from cardvalidator
        if (numberValidation.card) {
          if (numberValidation.card.type == "jcb") {
            setcard_type(jcb);
          } else if (numberValidation.card.type == "american-express") {
            setcard_type(amex);
          } else if (numberValidation.card.type == "visa") {
            setcard_type(visa);
          } else if (numberValidation.card.type == "mastercard") {
            setcard_type(mastercard);
          } else if (numberValidation.card.type == "discover") {
            setcard_type(discover);
          } else if (numberValidation.card.type == "diners-club") {
            setcard_type(diners);
          } else if (numberValidation.card.type == "unionpay") {
            setcard_type(unionpay);
          } else {
            setcard_type("");
          }
        } else {
          setcard_type("");
        }
      }
    }
  };
  const set_value_carddate = (current) => {
    // checks for a number as a input and also if cheks for while backspacing for '' and '/' if dsabled you wouldnt be able to backspace in card month
    if (
      Number(current.value.substr(-1)) == current.value.substr(-1) ||
      current.value.substr(-1) == " " ||
      current.value.substr(-1) == "/"
    ) {
      if (current.value.length < 8) {
        let data = "";
        // adds a ' / ' etween two numbers and doesnot lets user to enter the first number greater than 12 if it is then changes eg. 13 =  01 / 3
        if (
          [3].indexOf(current.value.length) > -1 &&
          carddate.length + 1 <= current.value.length
        ) {
          setcarddate(carddate + " / " + current.value.slice(-1));
          data = current.value.slice(0, 2) + "/" + current.value.slice(-1);
        } else {
          if (current.value > 12) {
            let date_arr = current.value.split("");
            setcarddate("0" + date_arr[0] + " / " + date_arr[1]);
            data = "0" + date_arr[0] + " / " + date_arr[1];
          } else {
            setcarddate(current.value);
            data = current.value;
          }
        }
      }
    }
  };
  const set_value_cardcvv = (current) => {
    if (current < 9999) {
      setcardcvv(current);
    }
  };

  const submit_to_juliettie = () => {
    if (!submit) {
      return;
    }
    setsignup(true);
    const url = "https://mv-api.juliettecleaners.com/save-card";
    let date = carddate.split("/").map((el) => el.trim());
    let body = {
      name: name,
      email: email,
      phone: phone.replace("(+1)", "").split(" ").join("").trim(),
      address1: add1,
      address2: add2,
      pincode: pincode,
      country: country,
      state: selectedstates,
      cc: cardno.split(" ").join(""),
      month: date[0],
      year: "20" + date[1],
      cvc: cardcvv,
    };
    axios
      .post(url, body)
      .then((response) => response.data)
      .then((data) => {
        if (data.Error) {
          if ((data.Error = "Invalid Card Details")) {
            setcardno("");
            setcarddate("");
            setcardcvv("");
            setcard_type("");
            setTimeout(() => {
              setcardno_err("Invalid Card Details");
            }, 0);
          } else {
            setfailure(true);
          }
        } else {
          var now = new Date();
          var time = now.getTime();
          now.setTime(time + 5000);
          document.cookie =
            `card_type = ${data.brand};expires=` +
            now.toUTCString() +
            ";path=/";
          document.cookie =
            `card_cvv = ${data.last4};expires=` + now.toUTCString() + ";path=/";
          navigate("/success");
        }
        setsignup(false);
      });
  };

  return (
    <div className="login">
      <div className="login_leftdiv">
        <div style={{ height: "max-content" }}>
          <img className="logo" src={logo} />
          <div className="logo_title">MONTEREY VALET INC.</div>
        </div>
      </div>
      <div className="login_rightdiv">
        <div>
          {/* <div className='pay_txt'>Pay with card</div> */}
          <label htmlFor="login_name">
            Name on card
            {name_required && <span className="required">REQUIRED</span>}
            <input
              value={name}
              className={name_required ? " inp_error" : ""}
              type="text"
              id="login_name"
              onChange={(e) => {
                setname(e.target.value);
                setname_required(e.target.value == "");
              }}
            ></input>
          </label>

          <label htmlFor="login_email">
            Email
            {email_required && <span className="required">REQUIRED</span>}
            <input
              className={email_err || email_required ? "inp_error" : ""}
              type="text"
              id="login_email"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
                setemail_required(e.target.value == "");
              }}
            ></input>
          </label>
          <div className={"error" + (email_err ? " err_show" : "")}>
            {email_err}
          </div>
          <label htmlFor="login_phone">
            Cell Phone
            {phone_required && <span className="required">REQUIRED</span>}
            <input
              className={phone_required ? "inp_error" : ""}
              type="text"
              id="login_phone"
              value={phone}
              onChange={(e) => {
                // setphone(e.target.value);
                OnChangePhone(e.target.value);
                // setphone_required(e.target.value == "");
              }}
            ></input>
          </label>
          {/* <div className={"error" + (email_err ? " err_show" : "")}>
            {email_err}
          </div> */}

          <label htmlFor="login_add1">
            {/* Address 1 */}
            Apartment
            {add1_required && <span className="required">REQUIRED</span>}
            <input
              value={add1}
              className={add1_required ? " inp_error" : ""}
              type="text"
              id="login_add1"
              onChange={(e) => {
                setadd1(e.target.value);
                setadd1_required(e.target.value == "");
              }}
            ></input>
          </label>
          <label htmlFor="login_add2">
            Address
            {add2_required && <span className="required">REQUIRED</span>}
            <input
              value={add2}
              className={add2_required ? " inp_error" : ""}
              type="text"
              id="login_add2"
              onChange={(e) => {
                setadd2(e.target.value);
                setadd2_required(e.target.value == "");
              }}
            ></input>
          </label>
          <div className="country_pincode">
            <label htmlFor="login_region">
              Country
              <select
                id="login_region"
                defaultValue="US"
                onChange={(e) => setcountry(e.target.value)}
              >
                {country_list.map((el) =>
                  el.code == "US" ? (
                    <option key={el.code} value={el.code}>
                      {el.name}
                    </option>
                  ) : (
                    <option key={el.code} value={el.code}>
                      {el.name}
                    </option>
                  )
                )}
              </select>
            </label>
            <label htmlFor="login_state">
              State
              {to_show_states ? (
                to_show_states.length != 0 ? (
                  <select
                    id="login_state"
                    onChange={(e) => setselectedstates(e.target.value)}
                  >
                    {to_show_states &&
                      to_show_states.map((el) => (
                        <option key={el.name} value={el.name}>
                          {el.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select
                    id="login_state"
                    onChange={(e) => setselectedstates(e.target.value)}
                    disabled
                  >
                    <option>No States</option>
                  </select>
                )
              ) : (
                <select
                  id="login_state"
                  onChange={(e) => setselectedstates(e.target.value)}
                  disabled
                >
                  {to_show_states &&
                    to_show_states.map((el) => (
                      <option key={el.name} value={el.name}>
                        {el.name}
                      </option>
                    ))}
                </select>
              )}
            </label>
          </div>

          <label htmlFor="login_add2">
            Zip Code
            {pincode_required && <span className="required">REQUIRED</span>}
            <input
              value={pincode}
              className={pincode_required ? " inp_error" : ""}
              type="number"
              id="login_add2"
              onChange={(e) => {
                setpincode(e.target.value);
                setpincode_required(e.target.value == "");
              }}
            ></input>
          </label>

          <label htmlFor="login_cardno">
            Card information
            {cardno_required && <span className="required">REQUIRED</span>}
            <div className="login_inputflex_main">
              <div className="login_inputflex">
                <input
                  className={
                    "inp_carddate" +
                    (carddate_err || carddate_required ? " inp_error" : "")
                  }
                  type="text"
                  id="login_carddate"
                  placeholder="MM / YY"
                  value={carddate}
                  onChange={(e) => {
                    set_value_carddate(e.target);
                    setcarddate_required(e.target.value == "");
                  }}
                ></input>
                <input
                  className={
                    "inp_cardcvv" +
                    (cardcvv_err || cardcvv_required ? " inp_error" : "")
                  }
                  type="text"
                  id="login_cardcvv"
                  placeholder="CVV"
                  value={cardcvv}
                  onChange={(e) => {
                    set_value_cardcvv(e.target.value);
                    setcardcvv_required(e.target.value == "");
                  }}
                ></input>
              </div>
              <div>
                <input
                  className={
                    "inp_cardno" +
                    (cardno_err || cardno_required ? " inp_error" : "")
                  }
                  type="text"
                  id="login_cardno"
                  placeholder="1234 1234 1234 1234"
                  value={cardno}
                  onChange={(e) => {
                    set_value_cardno(e);
                    setcardno_required(e.target.value == "");
                  }}
                ></input>
                <div className="card_type" id="card_type">
                  <div show={String(card_type != "")}>
                    <img src={card_type || ""} />
                  </div>
                  <div show={String(card_type == "" && !cardno_required)}>
                    <img src={visa} />
                    <img src={mastercard} />
                    <img src={amex} />
                    <div>
                      <img
                        className="card_type_animation not_visible"
                        src={unionpay}
                      />
                      <img
                        className="card_type_animation not_visible"
                        src={jcb}
                      />
                      <img
                        className="card_type_animation not_visible"
                        src={discover}
                      />
                      <img
                        className="card_type_animation visible"
                        src={diners}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </label>
          <div
            className={
              "error" +
              (cardno_err || carddate_err || cardcvv_err ? " err_show" : "")
            }
          >
            {cardno_err || carddate_err || cardcvv_err}
          </div>

          <div className="submit">
            <div
              className="submit_btn"
              submit={String(submit)}
              onClick={() => !signup && submit_to_juliettie()}
            >
              {signup ? (
                <div className="signingup">
                  Signing Up
                  <div className="signup_dots dot1">.</div>
                  <div className="signup_dots dot2">.</div>
                  <div className="signup_dots dot3">.</div>
                </div>
              ) : (
                <div>Sign Up</div>
              )}
              <img className="lock" src={lock} />
            </div>
          </div>
          <div className="termsandconditions">
            By using any of Juliette’s services, I agree to the &nbsp;
            <a
              href="https://www.whoisjuliette.com/terms-conditions"
              target="_blank"
            >
              Terms of Service
            </a>
            &nbsp; and &nbsp;
            <a
              href="https://www.whoisjuliette.com/terms-conditions"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
      {failure && <LoginFailure />}
    </div>
  );
};
